import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";

//超期库存记录列表
export function getExceedStockList(data){
    return http({
        url:'/exceedStockList/applyLog.htm',
        method:'post',
        data
    })
}
//超期库存记录列表
export function exportStock(data){
    return http({
        url:'/exceedStockList/applyLogExport.htm',
        method:'post',
        responseType: 'blob',
        data
    })
}