import { getExceedStockList,exportStock } from "./api.js";
import Util from "@/utils/utils";
import navList from "@/views/stockCenter/navList";

export default {
  data() {
    return {
      expLoading:false,
      pageLoadFlag: false,
      navList: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/stockCenter/shareLog",
          name: "/stockCenter/shareLog",
          title: "共享申请明细"
        }
      ],
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10,
        sortName: "",
        sortOrder: ""
      },
      scroll: {
        x: 3600
        // y: 400
      },
      columns: [
        {
          label: "申请编码",
          prop: "applyNo",
          sortable: 'custom',
          sortName: "exceedStockShareFlow.applyNo",
          width: "200px",
          key: "applyNo"
        },
        {
          label: "单据编码",
          prop: "orderNo",
          width: "200px",
        },
        {
          label: "流程状态",
          prop: "flowStatusName",
          sortable: 'custom',
          sortName: "status",
          key: "statusName"
        },
        {
          label: "销售组织",
          prop: "orgName",
          width: "200px",
          sortable: 'custom',
          sortName: "fwOrg.name",
          key: "orgName"
        },
        {
          label: "产品型号",
          prop: "zzprdmodel",
          width: "200px",
          sortable: 'custom',
          sortName: "productInfo.zzprdmodel",
          key: "zzprdmodel"
        },
        {
          label: "颜色",
          prop: "z_color",
          key: "z_color"
        },
        {
          label: "物料组",
          prop: "matklName",
          key: "matklName"
        },
        {
          label: "仓库",
          prop: "warehouseInfoLgortName",
          width: "200px",
          key: "warehouseInfoLgortName"
        },
        {
          label: "库存状态",
          prop: "invtypeName",
          key: "invtypeName"
        },
        {
          label: "质量等级",
          prop: "qualitylv",
          key: "qualitylv"
        },
        // {
        //   label: "补差类型",
        //   prop: "invstatusName",
        //
        //   key: "invstatusName"
        // },
        {
          label: "可共享数量",
          prop: "maxQuantity",
          align: "right",
          key: "maxQuantity"
        },
        {
          label: "申请共享数量",
          prop: "planqty",
          width: "150px",
          align: "right",
          key: "planqty"
        },
        {
          label: "允许共享数量",
          prop: "updateqty",
          width: "150px",
          align: "right",
          key: "updateqty"
        },
        {
          label: "实际共享数量",
          prop: "realqty",
          width: "150px",
          align: "right",
          key: "realqty"
        },
        {
          label: "变更说明",
          prop: "checkRemark",
          width: "250px",
          key: "checkRemark"
        },
        {
          label: "审批状态",
          prop: "flowStatus",
          key: "flowStatus"
        },
        {
          label: "最后审批时间",
          prop: "modifiedDateShow",
          width: "180px",
          key: "modifiedDateShow"
        },
        {
          label: "审批意见",
          prop: "auditMsg",
          width: "200px",
          key: "auditMsg"
        },
        {
          label: "申请时间",
          prop: "createdDateShow",
          width: "180px",
          key: "createdDateShow"
        },
        {
          label: "申请账号",
          prop: "creator",
          key: "creator"
        },
        {
          label: "申请平台",
          prop: "sourceTypeName",
          key: "sourceTypeName"
        }
      ],
      data: [],
      params: {}
    };
  },
  created() {
    this.navList = navList.nav2;
    this.getData();
  },
  methods: {
    searchFun(arg) {
      this.params = arg[0];
      this.pager.pageNo = 1;
      this.getData();
    },
    //排序change事件
    onTableChange(sorter) {
      const sortName = this.columns.filter(it=>it.prop==sorter.prop)
      if(sortName.length<1){
        return
      }
      if (sorter && sorter.order) {
        this.pager.sortOrder =
            sorter.order == "ascending"
                ? "asc"
                : sorter.order == "descending"
                ? "desc"
                : "";
        this.pager.sortName = sortName[0].sortName;
      } else {
        this.pager.sortOrder = "";
        this.pager.sortName = "";
      }
      this.getData();
    },
    exportFile(){
      this.expLoading = true;
      const data = {
        pageNo: 1,
        pageSize: 300000,
        orderByName: this.pager.sortName,
        orderByType: this.pager.sortOrder,
        ...this.params
      };
      exportStock(data).then(res => {
        Util.blobToJson(res.data).then(content=>{
          if(content&&content.msg=='success'){
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err=>{
          Util.downloadFile(res.data,'超期库存申请共享记录.xls')
        }).finally(()=>{
          this.expLoading = false;
        })
      }).catch(err=>{
        this.expLoading = false;
      });
    },
    getData() {
      const data = {
        pageNo: this.pager.pageNo,
        pageSize: this.pager.pageSize,
        orderByName: this.pager.sortName,
        orderByType: this.pager.sortOrder,
        ...this.params
      };
      getExceedStockList(data).then(res => {
        const start = (this.pager.pageNo - 1) * this.pager.pageSize;
        res.data.list = res.data.list || [];
        const codeToName = {
          "1": "PC",
          "2": "微信",
          "3": "APP"
        };
        res.data.list.forEach(item => {
          item.sourceTypeName = codeToName[item.sourceType];
          item.maxQuantity = Math.max(
            item.avbshareqty - (item.checkqty || 0),
            0
          );
          if (item.modifiedDate) {
            item.modifiedDateShow = Util.dateFmt(
              new Date(item.modifiedDate),
              "yyyy-MM-dd hh:mm:ss"
            );
          }
          if (item.createdDate) {
            item.createdDateShow = Util.dateFmt(
              new Date(item.createdDate),
              "yyyy-MM-dd hh:mm:ss"
            );
          }
        });
        this.data = res.data.list.slice(start, start + this.pager.pageSize);
        this.pager.count = res.data.list.length;
      });
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
